import * as React from "react";
import { useLogoAnimation } from "../../../hooks/useLogoAnimation";
import "./GameOfCodesLogo.scss";

const GameOfCodesLogo: React.FC = () => {
  const { svg_div_ref, svgState } = useLogoAnimation();
  return (
    <div ref={svg_div_ref} id="goc-logo-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="87.5%"
        height="87.5%"
        fill="none"
        viewBox="0 0 216 216"
        className={svgState}
        id="game-of-codes-1"
      >
        <g>
          <g id="G">
            <g className="Color Fill 2">
              <g className="Group">
                <path
                  fill="#fff"
                  d="M92.4 146.6c-11.3-5.1-22.6-10.3-33.9-15.4h-.2v15.1c4.7 2.1 9.4 4.3 14.1 6.4 2.7 5.8-6.7 6.8-10.6 5.2-10.4-4-15.9-12.1-15.9-26.7.1-2.9.2-5.9.3-8.9 3.2-13.7 19.1-4.2 23.6 1.8 1.7 2.2 1.7 5 3.1 7.7.9 1.5 4.1 2.1 5.7 2.8 4.5 2 9.1 4 13.6 6.1 1-5.4-1.8-11.7-3.6-15.2-6.8-13.6-18-22.2-32.3-28.3-8.4-3.6-21-4-26.2 2.8-6 7.8-6.7 25.1-3.8 37 5.4 21.8 19.8 37 42.9 40.8 3.9.6 7.5-1.2 10.5-1.6l.2.2c.7 2.7 1.3 5.3 2 8 .6 1 9.2 4.9 10.8 5.1l-.3-42.9z"
                  className="Vector"
                />
              </g>
            </g>
          </g>
          <g id="line-front">
            <path
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.5"
              d="M166 101.9l1.1 60.1"
              id="line-front4"
            />
            <path
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.5"
              d="M167.5 163.9l-62.2 35.7"
              id="line-front3"
            />
            <path
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.5"
              d="M121.6 150.5l45.3-25.7"
              id="line-front2"
            />
            <path
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.5"
              d="M166.2 99.3l28.7-15.5"
              id="line-front1"
            />
          </g>
          <g id="line-top">
            <path
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.5"
              d="M54.7 47l59.4-34.2"
              id="line-top1"
            />
            <path
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.5"
              d="M54.8 45.5l32.5 19.7"
              id="line-top2"
            />
            <path
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.5"
              d="M82.9 66.6L122.7 40"
              id="line-top3"
            />
            <path
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.5"
              d="M121.2 40.6l52.1 31.7"
              id="line-top4"
            />
          </g>
          <g id="spot-front">
            <g id="spot-front3">
              <g className="Group_2">
                <path
                  fill="#fff"
                  d="M165.7 95.6c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7 4.1-2.1 4.1-4.7c-.1-2.6-1.9-4.7-4.1-4.7z"
                  className="Vector_2"
                />
              </g>
              <g className="Group_3">
                <path
                  fill="#fff"
                  d="M165.7 95.6c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7 4.1-2.1 4.1-4.7c-.1-2.6-1.9-4.7-4.1-4.7z"
                  className="Vector_3"
                />
              </g>
            </g>
            <g id="spot-front2">
              <g className="Group_4">
                <path
                  fill="#fff"
                  d="M123.9 154.2c2.264 0 4.1-2.104 4.1-4.7s-1.836-4.7-4.1-4.7c-2.264 0-4.1 2.104-4.1 4.7s1.836 4.7 4.1 4.7z"
                  className="Vector_4"
                />
              </g>
              <g className="Group_5">
                <path
                  fill="#fff"
                  d="M123.9 144.8c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7c2.2 0 4.1-2.1 4.1-4.7s-1.8-4.7-4.1-4.7z"
                  className="Vector_5"
                />
              </g>
            </g>
            <g id="spot-front1">
              <g className="Group_6">
                <path
                  fill="#fff"
                  d="M167.3 160.1c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7c2.2 0 4.1-2.1 4.1-4.7s-1.9-4.7-4.1-4.7z"
                  className="Vector_6"
                />
              </g>
              <g className="Group_7">
                <path
                  fill="#fff"
                  d="M167.3 160.1c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7c2.2 0 4.1-2.1 4.1-4.7s-1.9-4.7-4.1-4.7z"
                  className="Vector_7"
                />
              </g>
            </g>
          </g>
          <g id="spot-top">
            <g id="spot-top3">
              <g className="Group_8">
                <path
                  fill="#fff"
                  d="M120.7 36.7c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7 4.1-2.1 4.1-4.7-1.9-4.7-4.1-4.7z"
                  className="Vector_8"
                />
              </g>
              <g className="Group_9">
                <path
                  fill="#fff"
                  d="M120.7 36.7c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7 4.1-2.1 4.1-4.7-1.9-4.7-4.1-4.7z"
                  className="Vector_9"
                />
              </g>
            </g>
            <g id="spot-top2">
              <g className="Group_10">
                <path
                  fill="#fff"
                  d="M85.6 59.6c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7 4.1-2.1 4.1-4.7-1.9-4.7-4.1-4.7z"
                  className="Vector_10"
                />
              </g>
              <g className="Group_11">
                <path
                  fill="#fff"
                  d="M85.6 59.6c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7 4.1-2.1 4.1-4.7-1.9-4.7-4.1-4.7z"
                  className="Vector_11"
                />
              </g>
            </g>
            <g id="spot-top1">
              <g className="Group_12">
                <path
                  fill="#fff"
                  d="M56.9 41.9c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7c2.2 0 4.1-2.1 4.1-4.7s-1.8-4.7-4.1-4.7z"
                  className="Vector_12"
                />
              </g>
              <g className="Group_13">
                <path
                  fill="#fff"
                  d="M56.9 41.9c-2.2 0-4.1 2.1-4.1 4.7s1.8 4.7 4.1 4.7c2.2 0 4.1-2.1 4.1-4.7s-1.8-4.7-4.1-4.7z"
                  className="Vector_13"
                />
              </g>
            </g>
          </g>
          <g id="Cube">
            <g className="Polygon 1">
              <g className="Group_14">
                <path
                  fill="#fff"
                  d="M105.5 9.4l86.8 50.1v100.2l-86.8 50.1-86.8-50.1V59.5l86.8-50.1zm0-5.3L14.1 56.8v105.4l91.3 52.7 91.3-52.7V56.8L105.5 4.1z"
                  className="Vector_14"
                />
              </g>
            </g>
            <path
              fill="#fff"
              d="M107.7 109.4h-5v103h5v-103z"
              className="Vector_15"
            />
            <path
              fill="#fff"
              d="M16.873 57.917l-2.5 4.33 89.198 51.5 2.5-4.33-89.198-51.5z"
              className="Vector_16"
            />
            <path
              fill="#fff"
              d="M107.2 114l-2.8-4.6L193 58.2l2.9 4.6-88.7 51.2z"
              className="Vector_17"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
export default GameOfCodesLogo;
