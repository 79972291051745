import * as React from "react";
import "./WebinarLogo.scss";
import { useLogoAnimation } from "../../../hooks/useLogoAnimation";

const WebinarLogo: React.FC = () => {
  const { svg_div_ref, svgState } = useLogoAnimation();

  return (
    <div className="logo-div" ref={svg_div_ref}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1000 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={svgState}
      >
        <g id="WebinarLogo">
          <path
            id="webinar-logo-left"
            d="M83 500L434.65 148.35V326.56L261.21 500L434.65 673.44V851.65L83 500Z"
            fill="white"
          />
          <path
            id="webinar-logo-right"
            d="M917 500L565.35 851.65V673.44L738.79 500L565.35 326.56V148.35L917 500Z"
            fill="white"
          />
          <path
            id="webinar-logo-center"
            d="M499.993 375.678L375.671 500L499.993 624.322L624.316 500L499.993 375.678Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
};
export default WebinarLogo;
