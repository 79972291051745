/**
 * Orientation.tsx
 *
 * Component for the Orientation section of the ACSS Week 2021 page.
 *
 */

import React from "react";
import { EventSectionLayout } from "../EventSectionLayout";
import OrientationLogo from "./OrientationLogo";

/**
 * Orientation
 * Component proper.
 */
export const Orientation: React.FC = () => {
  // For the Accordion group
  // const expandedItemRef = useRef<HTMLDivElement | null>(null); // state
  // const accordionToggle = useAccordionToggle(expandedItemRef); // handler

  return (
    <EventSectionLayout
      title="Orientation"
      tagline="An opportunity to transcend."
      description="Kickstart the week by getting a glimpse into our organization. Get to know our developers, executives, and our organization, and take the next step to join our organization and transcend!"
      schedule="Feb. 17, 2021 at 2:00 - 4:00 PM"
      platform={["Zoom"]}
      logo={<OrientationLogo />}
      reversedLayout
    >
      {/* <a
        href="/Orientation2021"
        className="register-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        Register
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="white"
        >
          <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
        </svg>
      </a> */}
    </EventSectionLayout>
  );
};
