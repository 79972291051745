/**
 * useAccordionToggle.tsx
 *
 * A react hook that returns a function that handles the toggling
 * of a group of accordion items
 */

import { MutableRefObject } from "react";

const useAccordionToggle = (
  expandedItemRef: MutableRefObject<HTMLDivElement | null>
): ((itemToExpand: HTMLDivElement | null) => void) => {
  const accordionToggle = (itemToExpand: HTMLDivElement | null) => {
    if (expandedItemRef.current == itemToExpand) {
      // Collapse the accordion item if it is already expanded
      itemToExpand?.classList.remove("expanded");
      expandedItemRef.current = null;
    } else {
      // Collapse the currently-expanded item
      expandedItemRef.current?.classList.remove("expanded");

      // Then expand the newly-selected one
      itemToExpand?.classList.add("expanded");
      expandedItemRef.current = itemToExpand;
    }
  };

  return accordionToggle;
};

export default useAccordionToggle;
