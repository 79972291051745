/**
 * Sponsors.tsx
 *
 * Component for the Sponsors section of the ACSS Week 2021 page.
 *
 */

import React from "react";
import { MajorSponsors } from "./MajorSponsors";
import { MediaPartners } from "./MediaPartners";
import { MinorSponsors } from "./MinorSponsors";

import "./Partners.scss";

/**
 * Sponsors
 * Component proper.
 */
export const Sponsors: React.FC = () => {
  return (
    <section className="partners-acss-week">
      <p className="transcend-logo">ACSS Week 2021:</p>
      <p className="transcend-logo enlarge">TRANSCEND</p>
      <p className="continuation">
        would not be possible without our sponsors:
      </p>
      <div className="partner-acss-week-blocks">
        <MajorSponsors />
        <MinorSponsors />
        <MediaPartners />
      </div>
    </section>
  );
};
