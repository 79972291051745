/**
 * Webinar.tsx
 *
 * Component for the Webinar section of the ACSS Week 2021 page.
 *
 */

import React, { useRef } from "react";
import useAccordionToggle from "../../../hooks/useAccordionToggle";
import { EventSectionLayout } from "../EventSectionLayout";
import WebinarLogo from "./WebinarLogo";
import { Accordion } from "../Accordion";
import { Speaker } from "../Speaker";
import { useStaticQuery, graphql } from "gatsby";

/**
 * CompanyTalks
 * Component proper.
 */
export const Webinar: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allImageSharp(
        filter: { fixed: { originalName: { in: ["matthew-seaver-choy.jpg"] } } }
        sort: { fields: fixed___originalName }
      ) {
        nodes {
          fixed(width: 100, quality: 100) {
            ...GatsbyImageSharpFixed
            originalName
          }
        }
      }
    }
  `);
  const speakers: Speaker[] = [
    {
      name: "Matthew Seaver Choy",
      origin: "Chief Technology Officer, Anteriore Inc.",
      topic: "Trends in Tools and Technology in Computer Science",
      image: data.images.nodes[0],
    },
  ];

  // For the Accordion group
  const expandedItemRef = useRef<HTMLDivElement | null>(null); // state
  const accordionToggle = useAccordionToggle(expandedItemRef); // handler

  return (
    <EventSectionLayout
      title="WebinUP"
      tagline="Bringing life to innovation."
      description="A one-day online seminar about technology and computer science. Get to know and understand recent news about technologies and concepts from our speaker."
      schedule="Feb. 18, 2021 at 3:00 - 5:00 PM"
      logo={<WebinarLogo />}
      speakers={speakers}
    >
      <Accordion title="Program Flow" toggleHandler={accordionToggle}>
        <table>
          <thead>
            <tr>
              <th>Time</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2:45 PM - 3:00 PM</td>
              <td>Setup</td>
            </tr>
            <tr>
              <td>3:00 PM - 3:10 PM</td>
              <td>Welcoming of Participants</td>
            </tr>
            <tr>
              <td>3:10 PM - 3:15 PM</td>
              <td>Official Opening and Welcome Remarks</td>
            </tr>
            <tr>
              <td>3:15 PM - 3:25 PM</td>
              <td>Ice Breaker</td>
            </tr>
            <tr>
              <td>3:25 PM - 3:30 PM</td>
              <td>Introduction of Speaker</td>
            </tr>
            <tr>
              <td>3:30 PM - 4:30 PM</td>
              <td>Start of Webinar</td>
            </tr>
            <tr>
              <td>4:30 PM - 4:45 PM</td>
              <td>Open Forum</td>
            </tr>
            <tr>
              <td>4:45 PM - 4:50 PM</td>
              <td>Distribution of Assessment</td>
            </tr>
            <tr>
              <td>4:50 PM - 4:55 PM</td>
              <td>Certificates</td>
            </tr>
            <tr>
              <td>4:55 PM - 5:00 PM</td>
              <td>Closing Remarks</td>
            </tr>
          </tbody>
        </table>
      </Accordion>
    </EventSectionLayout>
  );
};
