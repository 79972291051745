/**
 * MediaPartners.tsx
 *
 * Component for the Media Partners section of the ACSS Week 2021 page.
 *
 */

import { useStaticQuery, graphql } from "gatsby";
import Img, { GatsbyImageFixedProps } from "gatsby-image";
import React from "react";

/**
 * MediaPartners
 * Component proper.
 */
export const MediaPartners: React.FC = () => {
  const media_partner_images = useStaticQuery(graphql`
    query {
      images: allImageSharp(
        filter: {
          fixed: {
            originalName: {
              in: ["stp-uplb.png", "uphess.png", "uplb-eco-soc.png"]
            }
          }
        }
        sort: { fields: fixed___originalName }
      ) {
        nodes {
          fixed(width: 150, quality: 100) {
            ...GatsbyImageSharpFixed
            originalName
          }
        }
      }
    }
  `);

  return (
    <div className="partner-acss-week-block">
      <div className="partner-acss-week-images">
        {media_partner_images.images.nodes.map(
          (value: GatsbyImageFixedProps, i: number) => (
            <Img fixed={value.fixed} key={i} style={{ margin: "0.5rem" }}></Img>
          )
        )}
      </div>
      <h4>Media Partners</h4>
    </div>
  );
};
