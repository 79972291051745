import * as React from "react";
import "./CompanyTalksLogo.scss";
import { useLogoAnimation } from "../../../hooks/useLogoAnimation";

const CompanyTalksLogo: React.FC = () => {
  const { svg_div_ref, svgState } = useLogoAnimation();

  return (
    <div className="logo-div" ref={svg_div_ref}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1000 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={svgState}
      >
        <g id="CompanyTalksLogo">
          <path
            id="b-right"
            d="M772.62 625.1C805.55 586.37 825.44 536.19 825.44 481.35C825.44 358.75 726.06 259.37 603.46 259.37C480.87 259.37 381.49 358.75 381.49 481.35C381.49 603.94 480.87 703.32 603.46 703.32C653.28 703.32 699.27 686.9 736.32 659.19L807.83 701.62C818.94 708.21 830.77 694.26 822.46 684.38L772.62 625.1ZM603.46 617.34C528.36 617.34 467.47 556.45 467.47 481.35C467.47 406.24 528.36 345.36 603.46 345.36C678.57 345.36 739.45 406.24 739.45 481.35C739.45 556.45 678.57 617.34 603.46 617.34Z"
            fill="white"
          />
          <path
            id="b-left"
            d="M396.29 616.12C394.97 614.38 393.66 612.62 392.38 610.84C379.25 615.06 365.25 617.34 350.72 617.34C275.61 617.34 214.73 556.45 214.73 481.35C214.73 406.24 275.61 345.36 350.72 345.36C365.25 345.36 379.25 347.64 392.38 351.86C393.66 350.08 394.97 348.32 396.29 346.58C411.65 322.77 430.96 301.75 453.3 284.45C422.62 268.43 387.73 259.37 350.72 259.37C228.12 259.37 128.74 358.75 128.74 481.35C128.74 536.19 148.63 586.37 181.56 625.1L131.72 684.38C123.41 694.26 135.24 708.21 146.35 701.62L217.86 659.19C254.91 686.9 300.9 703.32 350.72 703.32C387.72 703.32 422.61 694.27 453.29 678.24C430.96 660.94 411.65 639.92 396.29 616.12Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
};
export default CompanyTalksLogo;
