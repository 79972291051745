/**
 * IntroACSSWeek.tsx
 *
 * Component for the intro section of the ACSS Week 2021 page.
 *
 */

import React, { useEffect, useState, useRef } from "react";

import "./IntroACSSWeek.scss";
import "../../../styles/layout.scss";
import acss_week_2021_logo from "../../../assets/img/acss-week-2021-logo.svg";

/**
 * IntroACSSWeek
 * Component proper.
 */
export const IntroACSSWeek: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  let frame = 0;
  const sticky_container_ref = useRef<HTMLDivElement>(null);

  const adjustScrollPosition = () => {
    if (sticky_container_ref.current) {
      setScrollPosition(
        window.pageYOffset >= sticky_container_ref.current.offsetTop
          ? window.pageYOffset - sticky_container_ref.current.offsetTop
          : 0
      );
    } else {
      setScrollPosition(0);
    }
  };

  const handleScrollThrottled = () => {
    if (frame) {
      cancelAnimationFrame(frame);
    }

    frame = requestAnimationFrame(adjustScrollPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollThrottled, {
      passive: true,
    });
    return () => {
      window.removeEventListener("scroll", handleScrollThrottled);
    };
  }, []);

  return (
    <section className="fill-in">
      <div className="sticky-container" ref={sticky_container_ref}>
        <div
          className="stack-container"
          style={{
            backgroundPosition: `0% max(calc(25vmax - ${
              scrollPosition * 0.025
            }vmax), 0vmax), 62.5vw calc(0vmax - ${
              scrollPosition * -0.0125
            }vmax), 15vw calc(20vmax - ${scrollPosition * 0.05}vmax)`,
          }}
        >
          <div className="logo-container">
            <img
              src={acss_week_2021_logo}
              className="acss-week-logo"
              alt="acss-week-logo"
            />
          </div>
          <header className="title">
            <h1 className="transcend-logo">ACSS Week 2021:</h1>
            <h1 className="transcend-logo enlarge">TRANSCEND</h1>
          </header>
          <p className="message">
            The Alliance of Computer Science Students UPLB is delighted to
            announce activities in celebration of ACSS Week 2021:
          </p>
        </div>
      </div>
    </section>
  );
};
