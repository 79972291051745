/**
 * Accordion.tsx
 *
 * Component for the shared layout of ACSS Week 2021 events sections.
 *
 */
import React, { useRef } from "react";

import "./Accordion.scss";

/**
 * AccordionProps
 * Component props.
 */
interface AccordionProps {
  title: string;
  toggleHandler: (item: HTMLDivElement | null) => void;
}

/**
 * Accordion
 * Component proper.
 */
export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  toggleHandler,
}) => {
  const accordion_ref = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    toggleHandler?.(accordion_ref.current);
  };

  return (
    <section className={`accordion-item`} ref={accordion_ref}>
      <header className="accordion-heading" onClick={handleClick}>
        <h3 className="accordion-title">{title}</h3>

        {/* PLUS ICON */}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g className="accordion-plus">
            <rect
              className="v-line"
              x="9.75"
              width="4.5"
              height="24"
              fill="white"
            />
            <rect
              className="h-line"
              x="24"
              y="9.75"
              width="4.5"
              height="24"
              transform="rotate(90 24 9.75)"
              fill="white"
            />
          </g>
        </svg>
      </header>
      <div className="accordion-content">
        {children ? children : "(No content.)"}
      </div>
    </section>
  );
};
