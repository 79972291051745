/**
 * Companies.tsx
 *
 * Component for the list of companies in the Companies Talk section
 *
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img, { GatsbyImageFixedProps } from "gatsby-image";

import "./CompanyTalks.scss";

/**
 * Companies
 * Component proper.
 */

interface Company {
  name: string;
  description: string;
  image: GatsbyImageFixedProps;
}

export const Companies: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allImageSharp(
        filter: {
          fixed: { originalName: { in: ["stratpoint.png", "talino.png"] } }
        }
        sort: { fields: fixed___originalName }
      ) {
        nodes {
          fixed(height: 65, quality: 100) {
            ...GatsbyImageSharpFixed
            originalName
          }
        }
      }
    }
  `);

  const companies: Company[] = [
    {
      name: "Stratpoint Technologies, Inc.",
      description:
        "Stratpoint enables digital transformation, delivering enterprise-grade solutions globally for more than 20 years. We specialize in agile software development, cloud, data, AI, and emerging technologies.",
      image: data.images.nodes[0],
    },
    {
      name: "Talino Venture Labs",
      description:
        "Talino Venture Labs is a global venture builder focused on creating Inclusion Technology Solutions. With our portfolio of game changing ventures, award-winning start ups and impact driven initiatives, we deliver critical services to understand markets by developing innovative technology and market shaping business models.",
      image: data.images.nodes[1],
    },
  ];

  return (
    <div className="companies-list">
      {companies.map((value) => (
        <div className="company-entry" key={value.name}>
          <Img fixed={value.image.fixed} style={{ marginBottom: "2rem" }}></Img>
          <header className="company-name">
            <h4>{value.name}</h4>
          </header>
          <p>{value.description}</p>
        </div>
      ))}
    </div>
  );
};
