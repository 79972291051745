import * as React from "react";
import { useLogoAnimation } from "../../../hooks/useLogoAnimation";
import "./OrientationLogo.scss";

const OrientationLogo: React.FC = () => {
  const { svg_div_ref, svgState } = useLogoAnimation();

  return (
    <div className="logo-div" ref={svg_div_ref}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="85%"
        height="85%"
        fill="none"
        viewBox="0 0 500 600"
        className={svgState}
      >
        <g>
          <g id="outer-bulb">
            <path
              fill="#fff"
              d="M459 280.9c0 95.2-70.1 174.1-161.4 187.8v-59.4c0-5.1 2.7-9.7 7.1-12.3l62.3-36c8.7-5.1 14.1-14.4 14.1-24.5v-96.7c0-10.1-5.4-19.4-14.1-24.5l-83.7-48.4c-8.8-5-19.6-5-28.3 0l-83.8 48.4c-8.7 5.1-14.1 14.4-14.1 24.5v96.7c0 10.1 5.4 19.4 14.1 24.5l62.7 36.2c4.4 2.5 7.1 7.2 7.1 12.3v59.3C149.4 455.2 79.2 376.3 79.2 281c0-105 85-190 189.9-190S459 176 459 280.9z"
            />
          </g>
          <g id="inner-bulb">
            <path
              fill="#fff"
              d="M291.5 365c-6.8 4-11 11.3-11.1 19.2v47.7c-3.7.3-7.5.4-11.2.4-3.7 0-7.3-.1-10.9-.4v-47.5c0-7.9-4.2-15.2-11.1-19.1l-49-28.3c-3.4-2-5.5-5.6-5.5-9.6v-75.5c0-4 2.1-7.6 5.5-9.6l65.4-37.8c3.4-2 7.7-2 11.1 0l65.4 37.8c3.4 2 5.5 5.6 5.5 9.6v75.5c0 4-2.1 7.6-5.5 9.6l-48.6 28z"
            />
          </g>
          <g id="upper-block">
            <path
              fill="#fff"
              d="M403.1 447.3v11.5c0 8.3-5.7 15-12.8 15H161.9c-7.1 0-12.8-6.7-12.8-15v-11.5c0-8.3 5.7-15 12.8-15h228.5c7-.1 12.7 6.7 12.7 15z"
            />
          </g>
          <g id="lower-block">
            <path
              fill="#fff"
              d="M403.1 501.2v11.5c0 8.3-5.7 15-12.8 15H161.9c-7.1 0-12.8-6.7-12.8-15v-11.5c0-8.3 5.7-15 12.8-15h228.5c7-.1 12.7 6.7 12.7 15z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
export default OrientationLogo;
