import React from "react";
import { ACSSWeek } from "../components/ACSSWeek/ACSSWeek";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";

const ACSSWeekPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="ACSS Week 2021: Transcend - ACSS UPLB" />
      <ACSSWeek />
    </Layout>
  );
};

export default ACSSWeekPage;
