/**
 * CompanyTalks.tsx
 *
 * Component for the Company Talks section of the ACSS Week 2021 page.
 *
 */

import React, { useRef } from "react";
import useAccordionToggle from "../../../hooks/useAccordionToggle";
import { EventSectionLayout } from "../EventSectionLayout";
import CompanyTalksLogo from "./CompanyTalksLogo";
import { Accordion } from "../Accordion";
import { Speaker } from "../Speaker";
import { useStaticQuery, graphql } from "gatsby";
import { Companies } from "./Companies";

/**
 * CompanyTalks
 * Component proper.
 */
export const CompanyTalks: React.FC = () => {
  const speaker_images = useStaticQuery(graphql`
    query {
      images: allImageSharp(
        filter: {
          fixed: {
            originalName: { in: ["jeff-herrera.png", "mark-colentava.png"] }
          }
        }
        sort: { fields: fixed___originalName }
      ) {
        nodes {
          fixed(width: 100, quality: 100) {
            ...GatsbyImageSharpFixed
            originalName
          }
        }
      }
    }
  `);

  const speakers: Speaker[] = [
    {
      name: "Jeff Herrera",
      origin: "Stratpoint Technologies Inc.",
      topic: "Software Industry: A Sneak Peak",
      image: speaker_images.images.nodes[0],
    },
    {
      name: "Mark Colentava",
      origin: "Talino Venture Labs",
      topic: "Life Inside an Impact-Driven Startup Company",
      image: speaker_images.images.nodes[1],
    },
  ];

  // For the Accordion group
  const expandedItemRef = useRef<HTMLDivElement | null>(null); // state
  const accordionToggle = useAccordionToggle(expandedItemRef); // handler

  return (
    <EventSectionLayout
      title="Company Talks"
      tagline="Beyond the classroom."
      description="Immerse yourself on different disciplines of computer science with talks from people from the industry. Get a clearer picture of the possible career paths for undergraduate students and obtain knowledge that are not taught inside the walls of the classroom."
      schedule="Feb. 19, 2021 at 2:00 - 5:00 PM"
      platform={["Zoom, Facebook Live"]}
      logo={<CompanyTalksLogo />}
      reversedLayout
      speakers={speakers}
    >
      <Accordion toggleHandler={accordionToggle} title="Companies">
        <Companies />
      </Accordion>
      <Accordion toggleHandler={accordionToggle} title="Program Flow">
        <table>
          <thead>
            <tr>
              <th>Time</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2:00 PM - 2:30 PM</td>
              <td>Waiting Time</td>
            </tr>
            <tr>
              <td>2:30 PM - 2:35 PM</td>
              <td>Icebreaker</td>
            </tr>
            <tr>
              <td>2:35 PM - 2:45 PM</td>
              <td>Official Opening and Welcome Remarks</td>
            </tr>
            <tr>
              <td>2:45 PM - 2:50 PM</td>
              <td>Introduction for the First Speaker</td>
            </tr>
            <tr>
              <td>2:50 PM - 3:35 PM</td>
              <td>Talk Proper</td>
            </tr>
            <tr>
              <td>3:35 PM - 3:45 PM</td>
              <td>Open Forum</td>
            </tr>
            <tr>
              <td>3:45 PM - 3:50 PM</td>
              <td>Bathroom Break</td>
            </tr>
            <tr>
              <td>3:50 PM - 3:55 PM</td>
              <td>Introduction for the Second Speaker</td>
            </tr>
            <tr>
              <td>3:55 PM - 4:40 PM</td>
              <td>Talk Proper</td>
            </tr>
            <tr>
              <td>4:40 PM - 4:50 PM</td>
              <td>Open Forum</td>
            </tr>
            <tr>
              <td>4:50 PM - 5:00 PM</td>
              <td>Recognition to the Speakers</td>
            </tr>
            <tr>
              <td>5:00 PM - 5:05 PM</td>
              <td>Closing Remarks</td>
            </tr>
          </tbody>
        </table>
      </Accordion>
      {/* <a
        href="/CompanyTalks2021"
        className="register-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        Register
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="white"
        >
          <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
        </svg>
      </a> */}
    </EventSectionLayout>
  );
};
