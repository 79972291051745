import React from "react";
import { IntroACSSWeek } from "./IntroACSSWeek/IntroACSSWeek";
import { GameOfCodes } from "./GameOfCodes/GameOfCodes";
import { CompanyTalks } from "./CompanyTalks/CompanyTalks";
import { Webinar } from "./Webinar/Webinar";
import { Orientation } from "./Orientation/Orientation";
import { Sponsors } from "./Sponsors/Sponsors";

import "../../styles/layout.scss";
import "./ACSSWeek.scss";

export const ACSSWeek: React.FC = () => {
  return (
    <main className="page-layout acss-week-2021-page">
      <IntroACSSWeek />
      <div className="events">
        <Orientation />
        <Webinar />
        <CompanyTalks />
        <GameOfCodes />
      </div>
      <Sponsors />
    </main>
  );
};
