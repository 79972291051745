/**
 * SpeakersLayout.tsx
 *
 * Component for the shared layout of ACSS Week 2021 events sections.
 *
 */
import React from "react";
import Img, { GatsbyImageFixedProps } from "gatsby-image";

import "./SpeakersLayout.scss";
import { Speaker } from "./Speaker";

interface SpeakerEntryProps {
  speaker: Speaker;
  isReversed?: boolean;
  fixedImage?: GatsbyImageFixedProps;
}

const SpeakerEntry: React.FC<SpeakerEntryProps> = ({
  speaker,
  isReversed = false,
  fixedImage = null,
}) => {
  return (
    <div className={`speaker ${isReversed ? "reversed" : ""}`}>
      {fixedImage && <Img fixed={fixedImage.fixed} alt="speaker image"></Img>}
      <div className="speaker-details">
        <h4 className="speaker-name">{speaker.name}</h4>
        <p className="speaker-origin">{speaker.origin}</p>
        <p className="speaker-origin">Topic: {speaker.topic}</p>
      </div>
    </div>
  );
};

/**
 * SpeakersLayoutProps
 * Component props.
 */
interface SpeakersLayoutProps {
  speakers: Speaker[];
}

/**
 * SpeakersLayout
 * Component proper.
 */
export const SpeakersLayout: React.FC<SpeakersLayoutProps> = ({ speakers }) => {
  return (
    <section className="speakers-section">
      <span className="label">Speakers:</span>
      <div className="speakers">
        {speakers.map((value, i) => {
          return (
            <SpeakerEntry
              speaker={value}
              key={`${value.name}${i}`}
              isReversed={i % 2 == 1}
              fixedImage={value.image ? value.image : undefined}
            />
          );
        })}
      </div>
    </section>
  );
};
