/**
 * GameOfCodes.tsx
 *
 * Component for the Game of Codes section of the ACSS Week 2021 page.
 *
 */

import React, { useRef } from "react";
import useAccordionToggle from "../../../hooks/useAccordionToggle";
import { EventSectionLayout } from "../EventSectionLayout";
import GameOfCodesLogo from "./GameOfCodesLogo";
import { Accordion } from "../Accordion";

import "./GameOfCodes.scss";

/**
 * GameOfCodes
 * Component proper.
 */
export const GameOfCodes: React.FC = () => {
  // For the Accordion group
  const expandedItemRef = useRef<HTMLDivElement | null>(null); // state
  const accordionToggle = useAccordionToggle(expandedItemRef); // handler

  return (
    <EventSectionLayout
      title="Game of Codes"
      tagline="Ready. Set. Code."
      description="The Game of Codes (GoC) is a modified ACM ICPC-style competitive programming competition, where teams of programmers will solve challenging programming and mathematical problems. Give it your best in earning the highest points against other competing teams in a limited amount of time, because prizes await for those who win the throne!"
      schedule="Feb. 20, 2021  at 1:00 - 4:00 PM"
      platform={["Zoom", "Discord", "HackerRank"]}
      logo={<GameOfCodesLogo />}
    >
      <Accordion
        toggleHandler={accordionToggle}
        title="Eligibility of Participants"
      >
        <h4>Member Eligibility</h4>
        <p>
          This contest is open to all high school and undergraduate students
          within the country who have a knack for programming.
        </p>

        <h4>Team Size and Number of Teams</h4>
        <p>
          Teams of up to three members will be allowed to compete. The maximum
          number of teams to compete is 20 teams.
        </p>

        <h4>Proof of Eligibility and Other Requirements</h4>
        <p>
          Participants are required to submit a photo of their student ID or any
          document that shows proof of their address. They are also required to
          use webcams during the event and expected to keep them on at all
          times.
        </p>
      </Accordion>
      <Accordion toggleHandler={accordionToggle} title="Event Registration">
        <h4>Pre-Registration</h4>
        <p>
          There will be no pre-registration for this year’s GoC, given that it
          will be held in an online setting. However, registration forms will be
          available earlier.
        </p>
        <h4>Fee and Procedure</h4>
        <p>
          The registration cost will be ₱100.00 per team. The registration will
          be done through Google Forms, and shall be posted by the ACSS official
          page a few weeks before the competition. Payment should be sent
          through GCash, or any remittance centers (Palawan Express, Cebuana
          Lhuillier, etc.), and must be received by a member of the Finance
          Team.
        </p>
        <h4>Registration Period and Deadlines</h4>
        <p>
          Online registration will be open until Friday, before the event.
          Deadline of payment is Saturday. If the participants were not able to
          pay the registration fee, their temporary slot will be forfeited.
        </p>

        <h4>HackerRank Accounts</h4>
        <p>
          Limited to twenty (20) HackerRank accounts shall be created by the
          participating teams prior to the event. Update on the number of
          remaining slots will be posted in the ACSS official page.
        </p>
      </Accordion>
      <Accordion toggleHandler={accordionToggle} title="Event Mechanics">
        <h4>Rules</h4>
        <ol>
          <li>
            At the start of the competition, each member must have their own
            computer and webcam or phone. Only the main coder of the team is
            required to have his/her computer ready. The problems will be
            carefully designed and tested by our specially-picked problem
            setters from the software industry, and will focus on the following
            topics: Logic, Mathematics, and Algorithms.
          </li>
          <li>
            A problem contains well-defined specifications of the input (i.e. a
            number or set of numbers; a string), and expected output given the
            input, memory limit, and code runtime limit in seconds.
          </li>
          <li>
            There will be two sets of given problems with three different
            difficulties. The teams have the freedom to choose which problem to
            solve first. However, its difficulty and equivalent points will not
            be stated. Updates in scores will be displayed live.
          </li>
          <li>
            To solve a problem, a contestant must first submit a source code
            solution in C, Java, or Python to a web interface called the
            ‘Judge’, which will run the program on the hidden test cases.
          </li>
          <li>
            A solution will be ‘accepted’ by the judge as correct if it produces
            the correct output of all hidden test cases within the specified
            time and memory limit. Otherwise, the appropriate error response is
            provided, and the team may modify their solution to fix the error
            and resubmit it.
          </li>
          <li>
            Each team must properly strategize their use of the time provided.
            Only one person should code at a time. The team must work together
            during the coding process.
          </li>
          <li>
            The rank of each team is based on the number of points accumulated
            within the time limit.
          </li>
          <li>
            In case of a tie, the teams with the same score will break the tie
            by playing in Blitz mode. A given problem will be given to each
            team. The first one to solve will get the points. A maximum of 5
            minutes will be allotted for this mode.
          </li>
        </ol>
        <h4>Challenge</h4>
        <h5>Shortest Code for a Given Problem For a Language</h5>
        <p>
          All teams may participate in this challenge. This mode will be opened
          at set times during the competition. A problem will be flashed on the
          screen and participants can answer for 5 minutes. The team that
          produces the shortest code per language (C, Java, Python) will be
          given merit for the problem. A team can submit in multiple languages
          but can only win merit for one. Answers given past the time limit will
          not be accepted.
        </p>
        <p>
          Answers will be submitted through Google Drive, each team will be
          given their own folder to upload their submissions in.
        </p>
        <h4>Other Notes</h4>
        <ul>
          <li>
            Teams should only use Discord as their communication platform during
            the contest proper, but should also be present during the initial
            and final parts of the program in Zoom.
          </li>
          <li>
            The main coder of the team should also share his/her screen via
            Discord.
          </li>
          <li>
            Participants are expected to keep their webcams on at all times.
          </li>
          <li>
            Physical judges are present in the Zoom call for clarifications.
          </li>
          <li>
            In case of a bathroom break, a participant should tell the
            facilitator assigned in their Discord channel and wait to be
            acknowledged.
          </li>
        </ul>
      </Accordion>
      <Accordion toggleHandler={accordionToggle} title="Program Flow">
        <table>
          <thead>
            <tr>
              <th>Time</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1:00 PM - 1:15 PM</td>
              <td>Introduction</td>
            </tr>
            <tr>
              <td>1:15 PM - 1:25 PM</td>
              <td>Game Mechanics</td>
            </tr>
            <tr>
              <td>1:25 PM - 1:35 PM</td>
              <td>HackerRank Demo</td>
            </tr>
            <tr>
              <td>1:35 PM - 2:35 PM</td>
              <td>Competition Proper</td>
            </tr>
            <tr>
              <td>2:35 PM - 2:45 PM</td>
              <td>Break</td>
            </tr>
            <tr>
              <td>2:45 PM - 3:35 PM</td>
              <td>Competition Proper</td>
            </tr>
            <tr>
              <td>3:35 PM - 4:00 PM</td>
              <td>Awarding and Closing</td>
            </tr>
          </tbody>
        </table>
      </Accordion>
      <Accordion toggleHandler={accordionToggle} title="Prizes">
        <h4>First Place</h4>
        <p>₱2,000.00</p>
        <h4>Second Place</h4>
        <p>₱1,500.00</p>
        <h4>Third Place</h4>
        <p>₱1,000.00</p>
      </Accordion>

      {/* <a
        href="/GameOfCodes2021"
        className="register-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        Register
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="white"
        >
          <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
        </svg>
      </a> */}
    </EventSectionLayout>
  );
};
