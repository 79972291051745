/**
 * useLogoAnimation.tsx
 *
 * This custom Hook allows a logo in the ACSS Week page to check if they are
 * visible in the viewport before animating.
 */

import { useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";

interface useLogoAnimationResult {
  svg_div_ref: React.RefObject<HTMLDivElement>;
  svgState: string;
}

/**
 * @returns A ref object for the div encapsulating the svg, and a string that will
 * insert a class name to the svg tag that will signal it to animate.
 */
export const useLogoAnimation = (): useLogoAnimationResult => {
  const svg_div_ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(svg_div_ref, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });
  const [svgState, setSvgState] = useState("initial");
  const [hasAlreadyIntersected, setHasAlreadyIntersected] = useState(false);

  useEffect(() => {
    if (!hasAlreadyIntersected && intersection?.isIntersecting) {
      setHasAlreadyIntersected(true);
      setSvgState("animate");
    }
  }, [intersection?.isIntersecting]);

  return { svg_div_ref, svgState };
};
