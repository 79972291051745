/**
 * EventSectionLayout.tsx
 *
 * Component for the shared layout of ACSS Week 2021 events sections.
 *
 */
import React from "react";
import { Speaker } from "./Speaker";

import "./EventSectionLayout.scss";
import { SpeakersLayout } from "./SpeakersLayout";

/**
 * EventSectionLayoutProps
 * Component props.
 */
interface EventSectionLayoutProps {
  title: string;
  tagline: string;
  logo: JSX.Element;
  description: string;
  schedule: string;
  platform?: string[];
  reversedLayout?: boolean;
  speakers?: Speaker[];
}

/**
 * EventSectionLayout
 * Component proper.
 */
export const EventSectionLayout: React.FC<EventSectionLayoutProps> = ({
  title,
  tagline,
  logo,
  description,
  schedule,
  platform,
  reversedLayout,
  speakers = null,
  children,
}) => {
  const additionalClass = reversedLayout ? "reversed" : "";

  return (
    <section className={`event-section-layout ${additionalClass}`}>
      <article className="event-text">
        <header>
          <h1 className="event-title">{title}</h1>
          <h2 className="event-tagline">{tagline}</h2>
        </header>
        <summary>
          <p className="event-description">{description}</p>
          <div className="event-main-details">
            <div className="event-schedule">
              <span className="label">
                Event Date: <br />
              </span>
              {schedule}
            </div>
            {platform ? (
              <div className="event-platform">
                <span className="label">
                  Platform: <br />
                </span>
                {platform.join(", ")}
              </div>
            ) : null}
          </div>
        </summary>
        {speakers && <SpeakersLayout speakers={speakers} />}
      </article>
      <div className="event-featured-image">{logo}</div>
      <div className="event-accordion-container">{children}</div>
    </section>
  );
};
